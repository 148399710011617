
import {
  defineComponent, onBeforeMount, reactive,
} from 'vue';
import { useAppBase } from '@/core/composables/AppBase';
import { ICaracteristica } from '@/models/Entidades/ICaracteristica';
import ComunicacaoApi from '@/core/components/UI/ComunicacaoApi.vue';
import EditorHtml from '@/core/components/UI/EditorHtml.vue';
import ServicoCaracteristica from '@/servicos/ServicoCaracteristica';
import { IResposta } from '@/core/models/IResposta';
import { ETipoCaracteristica } from '@/models/Enumeradores/ETipoCaracteristica';
import ItensListaSimples from '@/components/Caracteristicas/ItensListaSimples.vue';
import ItensListaSubItens from '@/components/Caracteristicas/ItensListaSubItens.vue';
import ItensCores from '@/components/Caracteristicas/ItensCores.vue';

export default defineComponent({
  name: 'CaracteristicaCadastro',
  components: {
    ComunicacaoApi,
    EditorHtml,
    ItensListaSimples,
    ItensListaSubItens,
    ItensCores,
  },
  setup() {
    const {
      appBase, apresentarComunicacaoApi, apresentarRespostaRedirecionamento, obterCodigoRota,
    } = useAppBase();
    const servicoCaracteristica = new ServicoCaracteristica();

    const state = reactive({
      caracteristica: {} as ICaracteristica,
    });

    async function obterCaracteristica(codigo:number) {
      appBase.carregando = true;
      state.caracteristica = await servicoCaracteristica.obter(codigo);
      appBase.carregando = false;
    }

    function limparDados() {
      state.caracteristica = {} as ICaracteristica;
      state.caracteristica.codigo = 0;
      state.caracteristica.tipo = ETipoCaracteristica.Texto;
      state.caracteristica.itens = [];
      state.caracteristica.status = true;
    }

    onBeforeMount(async () => {
      limparDados();
      const codigoCaracteristica = obterCodigoRota();
      if (codigoCaracteristica > 0) {
        await obterCaracteristica(codigoCaracteristica);
      }
    });

    async function salvar() {
      appBase.resposta = {} as IResposta;
      apresentarComunicacaoApi('Aguarde por favor... Estamos salvando as informações da Caracteristica.');
      if (state.caracteristica.codigo === 0) {
        appBase.resposta = await servicoCaracteristica.incluir(state.caracteristica);
      } else {
        appBase.resposta = await servicoCaracteristica.atualizar(state.caracteristica);
      }

      apresentarRespostaRedirecionamento('Caracteristicas');
    }
    return {
      appBase,
      state,
      salvar,
      ETipoCaracteristica,
    };
  },
});
